import { createSlice } from '@reduxjs/toolkit';

export const getModuleState = (state) => state.chat;

export const selectors = {
  getUnreadMessageCount(state) {
    const { unreadMessageCount } = getModuleState(state);

    return unreadMessageCount;
  },
  getChatDraftText(state, chatId) {
    if (!chatId) {
      return '';
    }

    const { draftText } = getModuleState(state).chats[chatId] || {};
    return draftText;
  },
  getExistingChats(state) {
    const { existingChats } = getModuleState(state);

    return existingChats;
  },
  getChatHistory(state, chatId) {
    if (!chatId) {
      return [];
    }

    return getModuleState(state).chatHistory[chatId] || [];
  },
};

const slice = createSlice({
  name: 'chat',
  initialState: {
    existingChats: [],
    chats: {},
    chatHistory: {},
    unreadMessageCount: 0,
  },
  reducers: {
    setUnreadMessageCount(state, { payload }) {
      state.unreadMessageCount = payload;
    },
    setDraftText(state, { payload }) {
      const { chatId, draftText } = payload;
      if (!state.chats[chatId]) {
        // Create the chat object if it doesn't exist
        state.chats[chatId] = {};
      }
      state.chats[chatId].draftText = draftText;
    },
    setExistingChats(state, { payload }) {
      state.existingChats = JSON.parse(JSON.stringify([...payload[0]]));
    },
    updateExistingChats(state, { payload }) {
      const payloadClone = JSON.parse(JSON.stringify(payload));
      let index = state.existingChats.findIndex(
        (chat) => chat.url === payloadClone.url
      );
      if (index >= 0) {
        state.existingChats[index] = payloadClone;
      } else {
        state.existingChats = [...state.existingChats, payloadClone];
      }
    },
    setChatHistory(state, { payload }) {
      const { chatId, history } = payload;
      if (!state.chatHistory[chatId]) {
        state.chatHistory[chatId] = [];
      }
      state.chatHistory[chatId] = history;
    },
    addChatMessage(state, { payload }) {
      const { chatId, message } = payload;
      if (!state.chatHistory[chatId]) {
        state.chatHistory[chatId] = [];
      }
      state.chatHistory[chatId].push(message);
    },
    prependChatMessage(state, { payload }) {
      const { chatId, messages } = payload;
      if (!state.chatHistory[chatId]) {
        state.chatHistory[chatId] = [];
      }
      state.chatHistory[chatId] = [...messages, ...state.chatHistory[chatId]];
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
